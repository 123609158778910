<template>
  <v-container fill-height>
    <loginDekstop
      v-if="
        this.$vuetify.breakpoint.name == 'md' ||
        this.$vuetify.breakpoint.name == 'lg' ||
        this.$vuetify.breakpoint.name == 'xl'
      "
    />

    <loginMobile v-else />
  </v-container>
</template>

<script>
import loginDekstop from "@/components/login/loginDekstop";
import loginMobile from "@/components/login/loginMobile";

export default {
  components: {
    loginDekstop,
    loginMobile,
  },

  data: () => ({}),
};
</script>